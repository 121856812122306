export default ({groupsSelect, copyPermissions, allTags, groupId, newUsersTags, addNewUsers}) => {
  // group form
  const groupForm = [
    {
      id: 'labelDetails',
      tag: 'label',
      content: 'Dados Básicos'
    },

    // group name
    {
      id: 'name',
      model: 'name',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Nome',
        invalidLabel: 'Insira um nome',
        tabIndex: 1,
        readOnly: groupId
      }
    },

    // visible groups
    {
      id: 'group_types_id',
      model: 'group_types_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Grupos visíveis',
        label: 'Grupos visíveis',
        placeholder: 'Selecione',
        invalidLabel: 'Escolha um tipo',
        tags: true,
        multiple: true,
        clearable: false,
        selectOptions: groupsSelect
      },
    },

    // select users
    ...(!groupId ? [{
      id: 'users',
      model: 'users',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Vincular usuários',
        label: 'Vincular usuários',
        placeholder: 'Selecione',
        invalidLabel: 'Escolha um usuário',
        multiple: true,
        clearable: false,
        suggestions: 'searchUsersWithGroups'
      },
      events: {
        input: 'input'
      }
    }] : []),

    // base tags
    ...(allTags.length ? [{
      id: 'usersTag',
      tag: 'FiltersTag',
      props: {
        tags: allTags,
        readOnly: !!groupId,
        label: 'Usuários'
      },
      events: {
        removeTag: 'removeTag',
        clear: 'clear'
      }
    },
    ]: []),

    // User label visible only at edit group page
    ...(groupId ? [{
      id: 'usersLabel',
      tag: 'label',
      content: 'Usuários'
    }] : []),

    // add new users checkbox
    ...(groupId ? [{
      id: 'addNewUsers',
      model: 'addNewUsers',
      tag: 'BaseCheckBox',
      style: 'align-self: end',
      props:{
        name: 'Deseja adicionar novos usuários ao grupo?',
      },
    }] : []),
    
    // select new users
    ...(groupId && addNewUsers ? [{
      id: 'newUsers',
      model: 'newUsers',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Vincular novos usuários',
        label: 'Vincular novos usuários',
        placeholder: 'Selecione',
        invalidLabel: 'Escolha um usuário',
        multiple: true,
        clearable: false,
        suggestions: 'searchUsersWithGroups'
      },
      events: {
        input: 'inputNewUsers'
      }
    }] : []),

    // base tags for new users
    ...(newUsersTags.length && addNewUsers ? [{
      id: 'newUsersTags',
      tag: 'FiltersTag',
      props: {
        tags: newUsersTags,
        label: 'Usuários'
      },
      events: {
        removeTag: 'removeTag',
        clear: 'clear'
      }
    },
    ]: []),

    
    
    // copy permissions
    ...(!groupId ? [{
      id: 'copyPermissions',
      model: 'copyPermissions',
      tag: 'BaseCheckBox',
      style: 'align-self: end',
      props:{
        name: 'Deseja copiar as permissões de outro grupo?',
      },
    }] : []),

    // copy permissions select
    ...(copyPermissions ? [{
      id: 'groupCopied',
      model: 'groupCopied',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Copiar permissões do grupo:',
        label: 'Copiar permissões do grupo:',
        placeholder: 'Selecione',
        invalidLabel: 'Escolha um tipo',
        tags: true,
        multiple: false,
        selectOptions: groupsSelect
      },
      events: {
        input: 'searchGroupPermissions',
      }
    }] : []),
    
    // Permissions
    {
      id: 'labelPermissions',
      tag: 'label',
      content: 'Permissões'
    },

  ]
  
  return [
    ...groupForm
  ]
}