<template>
  <main class="Groups">
    <TheSubHeader
      icon="profile"
      title="Grupos"
      page-title-tag="Groups"
      type="filter"
      :actions="canCreateGroup"
      organizer-gap="1rem"
      organizer-columns="3fr repeat(2, 1fr)" 
    >
      <template #filter>
        <!-- User Type -->
        <BaseTreeSelect
          v-model="filter.typeSelected"
          name="Tipo"
          label="Tipo"
          placeholder="Selecione"
          multiple
          :select-options="groupsSelect"
          @input="resetSearch()"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    <div class="Users__Content">
      <div class="Users__Table">
        <DataTable
          :header="groups.data.headers"
          :table="groups.data.content"
          is-sticky
          @see="goToUsersPage"
          @remove="removeGroup"
          @edit="editGroup"
        />
        <Pagination @page="search()" @paginate="search()" />
      </div>
    </div>
  </main>
</template>

<script>
import { BaseTreeSelect } from '@/components/atoms'
import { Pagination, DataTable, FiltersTag } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Groups',
  components: {
    DataTable,
    BaseTreeSelect,
    Pagination,
    TheSubHeader,
    FiltersTag
  },

  data() {
    return {
      filter:{
        typeSelected: []
      },
      allTags: [],
      show: false,
    }
  },

  computed:{
    ...mapState({
      stores: state => state.stores.stores,
      groupsSelect: state => state.groups.groupsSelect,
      queryString: state => state.queryString.queryString,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      permissions: state => state.auth.permissions,
      groups: state => state.groups.groups
    }),

    canCreateGroup() {
      return this.permissions?.groups?.addNewButton ? ['addnew'] : []
    },
  },

  mounted(){
    this.fetchGroupSelect()
    this.search()
  },

  methods:{
    ...mapActions({
      fetchGroups: 'groups/fetchGroups',
      fetchGroupSelect: 'groups/fetchGroupSelect',
      deleteUser: 'users/deleteUser',
      setParameters: 'queryString/setParameters',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      fetchGroupsList: 'groups/fetchGroupsList'
    }),

    editGroup(data){
      this.openInNewTab(`/groups/${data.id}`)
    },

    removeGroup(data){
      this.userId = data.id
      this.show = true
    },

    updateTags(){
      this.allTags = [...this.filter.typeSelected]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filter.typeSelected = this.filter.typeSelected.filter((tag) => tag.name !== name)
      
      this.resetSearch()
    },

    clearAllTags(){
      this.allTags = []
      this.filter.typeSelected = []

      this.resetSearch()
    },

    resetSearch() {
      this.clearPagination(this.paginate).then(() => this.search())
    },

    async search() {
      const params = {
        obj: {
          id: this.filter.typeSelected
        },
        paginate: this.paginate,
        page: this.page
      }

      this.updateTags()
      await this.fetchGroupsList(params)
      this.setPagination(this.groups)
    },

    goToUsersPage(data) {
      this.$router.push({
        name: 'users',
        params: {
          type: data.name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Users {
  &__Content{
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }
  &__Table{
    grid-area: main;
    margin-bottom: 50px;
  }
}
</style>