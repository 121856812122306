<template>
  <main class="GroupsForm">
    <TheSubHeader 
      icon="profile" 
      :title="groupTitle"
      page-title-tag="Groups Form"
    >
      <template #afterButtons>
        <BaseButton
          icon="chevron-left-alt"
          label="voltar"
          theme="icon-left"
          :track="trackSubheaderButtonString('Groups Form', 'back')"
          @click="$router.push('/groups')"
        />
      </template>
    </TheSubHeader>
    <div class="GroupsForm__content">
      <DynamicForm
        class="GroupsForm__form"
        :model="form"
        :blueprint="blueprint({groupsSelect, copyPermissions: !!form.copyPermissions, allTags, groupId, newUsersTags, addNewUsers: !!form.addNewUsers})"
        :endpoint-errors="endpointErrors"
        :style="[dynamicStyle]"
        submit-type="json"
        @input="updateTags"
        @inputNewUsers="updateTags"
        @removeTag="removeTag"
        @clear="clearAllTags"
        @searchGroupPermissions="copyGroupPermissions"
      />
    </div>

    <!-- modules permissions -->
    <div v-if="form.permissions" class="GroupsForm__permissions-container">
      <div v-for="item in form.permissions" :key="moduleId + item.id" class="GroupsForm__permissions">
        <div class="GroupsForm__item" @click="handlerPermissionsCollapse(item.name)">
          <Icon
            name="chevron-right-alt"
            class="GroupsForm__icon"
            :class="{'GroupsForm__icon--opened': permissionsCollapse[item.name]}"
          />
  
          <span class="GroupsForm__title">{{ item.display_name }}</span>
          
          <BaseToggle
            :id="moduleId + item.id"
            v-model="item.has_permission"
            @input="modulesToggle($event, item)"
          />
        </div>
  
        <!-- pages permissions -->
        <div v-if="isCollapseOpen(item.name)" class="GroupsForm__permissions--opened">
          <div v-for="(permission, index) in item.permissible" :key="permission.permissible_name">
            <div class="GroupsForm__each-permission" @click="handlerPermissionsCollapse(permission.permissible_name)">
              <Icon
                name="chevron-right-alt"
                class="GroupsForm__icon"
                :class="{'GroupsForm__icon--opened': permissionsCollapse[permission.permissible_name]}"
              />

              <span class="GroupsForm__title">{{ permission.permissible_display_name }}</span>

              <BaseToggle
                :id="permission.permissible_name"
                v-model="permission.has_permission"
                @input="pagesToggle($event, permission, item.permissible)"
              />
            </div>
            
            <!-- each page permissions -->
            <div v-if="isCollapseOpen(item.permissible[index].permissible_name)" class="GroupsForm__permissions--opened">
              <div v-for="child in permission.permission_data" :key="child.id" class="GroupsForm__each-permission">
                <span class="GroupsForm__title GroupsForm__title--child">{{ child.display_name }}</span>

                <BaseToggle
                  :id="child.id"
                  v-model="child.has_permission"
                  @input="permissionsToggle($event, child, item.permissible, permission.permission_data)"
                />
              </div>
            </div> 
          </div>
        </div>
      </div>

      <BaseButton
        icon="save"
        class="GroupsForm__save-button"
        color="primary-base"
        text-color="white"
        filled
        label="Salvar"
        type="submit"
        @click="onSubmit"
      />
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { DynamicForm } from '@/components/molecules'
import { BaseToggle, BaseButton } from '@/components/atoms'
import { TheSubHeader } from '@/components/organisms'
import blueprint from './GroupsForm.blueprint'
import { groups } from '@/api'

export default {
  name: 'GroupsForm',
  components: {
    TheSubHeader,
    DynamicForm,
    BaseToggle,
    BaseButton
  },
  
  data() {
    return {
      form: {
        group_types_id: null,
        name: '',
        users: [],
        newUsers: [],
        copyPermissions: false,
        addNewUsers: false,
        groupCopied: null,
        permissions: []
      },
      allTags: [],
      newUsersTags: [],
      permissionsCollapse: {},
      endpointErrors: {},
      permissionsToSend: []
    }
  },

  computed: {
    ...mapState({
      groupsSelect: state => state.groups.groupsSelect,
      permissions: state => state.auth.permissions,
      groupPermissions: state => state.groups.groupPermissions,
    }),
    
    groupId() {
      return this.$route.params.id
    },

    moduleId() {
      return Math.random()
    },

    groupTitle() {
      return this.groupId ? (this.form?.name || 'Editar Grupo') : 'Adicionar Grupo'
    },

    dynamicStyle() {
      let styleFields = ''

      if(this.groupId) {
        styleFields = `
        "usersLabel usersLabel . . . . . . . . . ."
        "usersTag usersTag usersTag usersTag usersTag usersTag usersTag usersTag usersTag usersTag usersTag usersTag"
        "addNewUsers addNewUsers addNewUsers addNewUsers . . . . . . . ."
        "newUsers newUsers newUsers newUsers . . . . . . . ."
        "newUsersTags newUsersTags newUsersTags newUsersTags newUsersTags newUsersTags newUsersTags newUsersTags newUsersTags newUsersTags newUsersTags newUsersTags" 
        "labelPermissions labelPermissions . . . . . . . . . ."`
      } else {
        styleFields = `
        "users users users users . . . . . . . ."
        "usersTag usersTag usersTag usersTag usersTag usersTag usersTag usersTag usersTag usersTag usersTag usersTag"
        ". . . . . . . . . . . ."
        "copyPermissions copyPermissions copyPermissions copyPermissions . . . . . . . ."
        "groupCopied groupCopied groupCopied groupCopied . . . . . . . ."
        ". . . . . . . . . . . ."
        "labelPermissions labelPermissions . . . . . . . . . ."`
      }

      return {'--dynamic-style': styleFields}
    }
  },

  async mounted() {
    this.setLoading(true)
    if (this.groupId) {
      await this.fetchGroupInformation()
    } else {
      await this.fetchPermissionsList()
    }
    this.fetchGroupSelect()
    this.setTogglesData()
    this.setLoading(false)
  },

  methods: {
    blueprint,
    ...mapActions({
      fetchGroupSelect: 'groups/fetchGroupSelect',
      setLoading: 'loading/setLoading',
      setAlert: 'error/setAlert'
    }),

    // set toggles data
    setTogglesData() {
      this.form.permissions.forEach((e) => {
        this.$set(this.permissionsCollapse, e.name, false)
        e.permissible.forEach((x) => {
          this.$set(this.permissionsCollapse, x.permissible_name, false)
        })
      })
    },

    // fetch group information with groupId
    async fetchGroupInformation() {
      await groups.fetchGroupPermissions(this.groupId, (res) => {
        for (let i in this.form) {
          if (res[i]) this.form[i] = res[i]
        }
        this.form.permissions = res.permissions_categories
        this.form.group_types_id = res.visible_groups.map(el => el.visible_roles)
        this.setInitialPermissions()
        this.updateTags()
      }), (e) => e
    },

    // fetch permissions list 
    async fetchPermissionsList() {
      await groups.fetchPermissions((res) => this.form.permissions = res, (e) => e)
    },

    // subit to create or edit group
    async onSubmit() {
      this.setLoading(true)
      const users = this.form.newUsers.length && this.groupId ? [...this.form.users?.map(el => el.id), ...this.form.newUsers.map(el => el.id)] : this.form.users?.map(el => el.id)
      const payload = {
        id: this.groupId,
        body: {
          name: this.form.name,
          visibles_groups: this.form.group_types_id?.map(el => el.id),
          users,
          permissions: this.permissionsToSend
        }
      }

      const endpoint = this.groupId ? 'updateGroup' : 'createGroup'

      await groups[endpoint](payload, () => {
        this.setAlert(this.groupId ?  'Grupo atualizado com sucesso!' : 'Grupo criado com sucesso!')
        this.$router.push({ name: 'groups'})
      },  (e) => {
        this.endpointErrors = e.validation
        this.setAlert(e?.message)
      }, () => this.setLoading(false))
    },

    // copy group permissions
    async copyGroupPermissions(data) {
      this.setLoading(true)

      if(!data) {
        await this.fetchPermissionsList()
        this.setLoading(false)
        return
      }

      await groups.fetchGroupPermissions(data.id, (res) => {
        this.form.permissions = res.permissions_categories
        this.setInitialPermissions()
      }, (e) => e, () => this.setLoading(false))
    },
    
    // return if permission collapse is open
    isCollapseOpen(name) {
      return !!this.permissionsCollapse[name]
    },

    // handler with permissions collapses
    handlerPermissionsCollapse(name) {
      this.permissionsCollapse[name] = !this.permissionsCollapse[name]
    },

    // handler with modules toggle
    modulesToggle(event, item) {
      item.has_permission = event

      item.permissible.map(el => {
        el.has_permission = event,
        el.permission_data.map(i => {
          i.has_permission = event
          this.addPermissionsToArray(i)
        })
      })
    },

    // handler with pages toggle
    pagesToggle(event, item, permissibles) {
      item.has_permission = event

      item.permission_data.map(i => {
        i.has_permission = event
        this.addPermissionsToArray(i)
      })

      const module = this.form.permissions.find(el => el.id === item.permission_category_id)
      module.has_permission = event

      // has some true permission
      if(permissibles.find(el => el.has_permission)) {
        module.has_permission = true
      }
    },

    // handler with permission toggle
    permissionsToggle(event, item, permissible, permissions_data) {
      item.has_permission = event

      this.addPermissionsToArray(item)

      // define module permission and page permission
      const module = this.form.permissions.find(el => el.id === item.permission_category_id)
      module.has_permission = event

      const page = permissible.find(el => el.permissible_name === item.permissible_name)
      page.has_permission = event


      // has some true module
      permissible.forEach((m) => {
        m.permission_data.forEach((i) => {
          if(i.has_permission) module.has_permission = true
        })
      })

      // has some true page
      permissions_data.forEach((p) => {
        if(p.has_permission) {
          module.has_permission = true
          page.has_permission = true
        }
      })
    },

    // set initial permission
    setInitialPermissions() {
      this.form.permissions.forEach((module) => {
        module.permissible.forEach((page) => {
          page.permission_data.forEach((permission) => {
            if(permission.has_permission) {
              this.addPermissionsToArray(permission)
            }
          })
        }) 
      })
    },

    // add permissions to an array to be save
    addPermissionsToArray(item) {
      if(!item.has_permission) {
        this.$nextTick(() => {
          this.permissionsToSend = this.permissionsToSend.filter((v) => v !== item.permission.name)
        })
      }
      this.permissionsToSend.push(item.permission.name)
    },

    updateTags(){
      this.allTags = [...this.form.users]
      this.newUsersTags = [...this.form.newUsers]
    },

    removeTag(name, id){
      if(this.newUsersTags.length) {
        this.newUsersTags = this.newUsersTags.filter((tag) => tag.id !== id)
        this.form.newUsers = this.form.newUsers.filter((tag) => tag.name !== name)
      } else {
        this.allTags = this.allTags.filter((tag) => tag.id !== id)
        this.form.users = this.form.users.filter((tag) => tag.name !== name)
      }
    },

    clearAllTags(){
      if(this.newUsersTags.length) {
        this.newUsersTags = []
        this.form.newUsers = []
      } else {
        this.allTags = []
        this.form.users = []
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.GroupsForm {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
    margin-bottom: 25px;
  }

  &__form {
    grid-template-areas:
      "labelDetails labelDetails . . . . . . . . . ."
      "name name name name group_types_id group_types_id group_types_id group_types_id . . . ."
      var(--dynamic-style)
  }

  &__permissions-container {
    max-width: 1180px;
    margin: 0 auto;
    padding-bottom: 20px;
    color: #393939;

    /deep/ .scrollbar {
      margin: 0;
      width: 500px !important;
    }

    @media #{$mobile-view} {
      max-width: 100%;
    }
  }

  &__permissions {
    padding: 10px;
    width: 400px;
    

    @media #{$mobile-view} {
      width: 100%;
      padding: 10px 1rem;
    }
  }

  &__item {
    display: flex; 
    justify-content: space-around;
    padding-bottom: 10px; 
    border-bottom: 1px solid #e7e4e4; 
    width: 400px;
    align-items: center;

    @media #{$mobile-view} {
      width: 100%;
    }
  }

  &__icon {
    width: 5px; 
    transition: all .2s ease;

    &--opened {
      transform: rotate(90deg);
    }
  }

  &__title {
    width:300px;
    font-size: 0.75rem;
    font-weight: 900;
    cursor: pointer;

    &--child {
      margin-left: 30px !important;
    }

    @media #{$mobile-view} {
      width:280px;

      &--child {
        margin-left: 15px !important;
      }
    }
  }

  &__each-permission {
    width: 360px;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    margin-left: 32px;
    align-items: center;

    span {
      margin-left: 15px;
    }

    @media #{$mobile-view} {
      width: calc(100% - 20px);
      margin-left: 15px;
    }
  }

  &__save-button {
    width: 120px; 
    margin-top: 20px; 
    margin-left: auto;
  }
}
</style>